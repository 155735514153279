export const imageConditions = [
  { level: 1, condition: { subscription: true } },
  { level: 2, condition: { subscription: true, activatedDirect: 2 } },
  {
    level: 3,
    condition: {
      subscription: true,
      activatedDirect: 3,
      activatedPackageMonth: 3,
    },
  },
  {
    level: 4,
    condition: {
      subscription: true,
      activatedDirect: 4,
      activatedPackageMonth: 3,
    },
  },
  {
    level: 5,
    condition: {
      subscription: true,
      activatedDirect: 5,
      activatedPackageMonth: 6,
    },
  },
  {
    level: 6,
    condition: {
      subscription: true,
      activatedDirect: 7,
      activatedPackageMonth: 6,
    },
  },
  {
    level: 7,
    condition: {
      subscription: true,
      activatedDirect: 9,
      activatedPackageMonth: 6,
    },
  },
  {
    level: 8,
    condition: {
      subscription: true,
      activatedDirect: 11,
      activatedPackageMonth: 12,
    },
  },
  {
    level: 9,
    condition: {
      subscription: true,
      activatedDirect: 13,
      activatedPackageMonth: 12,
    },
  },
  {
    level: 10,
    condition: {
      subscription: true,
      activatedDirect: 15,
      activatedPackageMonth: 12,
    },
  },
];

export const TeamConditions = {
  5: 200,
  6: 260,
  7: 320,
  8: 380,
  9: 440,
  10: 500,
};
