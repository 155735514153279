import React, { createContext, useState, useEffect, useMemo } from "react";
import Axios from "axios";
import ApiConfig, { socketURL } from "src/config/APICongig";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
// import WebSocket from 'websocket';
import Websocket from "react-websocket";
import { Box, Button, Dialog, Typography, makeStyles } from "@material-ui/core";
import { create, set } from "lodash";
import { CardTravel } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "fit-content",
    borderRadius: "10px",
    background: "transparent",
    boxShadow: " 0px 1px 3px 0px rgba(0, 0, 0, 0.0)",
    overflow: "hidden",
    "& .MuiDialogContent-root": {
      flex: "none !important",
    },
    "& .MuiDialogActions-root": {
      marginRight: "0px !important",
    },
  },
  dialogDiv: {
    float: "right",
    cursor: "pointer",
    display: "flex",
    justifyContent: "end",
    marginRight: "-11px",
  },
  dialogBnnerDiv: {
    padding: "0px",
    overflow: "hidden",
  },
  dialogBnnerImg: {
    maxWidth: "444px",
    borderRadius: "10px !important",
    overflow: "hidden",
  },
  rootDialogBox: {
    backgroundImage: "url('/images/alertBackground.svg') !important",
    backgroundSize: "cover !important",
    borderRadius: "10px",
    backgroundRepeat: "no-repeat",
    background: "transparent",
    backgroundColor: "#161616",
  },
  subMainHead: {
    background: "linear-gradient(142deg, #FFF 0%, #008BD9 100%)",
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "24px",
    lineHeight: "normal",
  },
  subMain: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "24px",
    color: "#fff",
  },
  subMainSub: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "24px",
    color: "#fff",
    textAlign: "center",
  },
  DisableButton: {
    borderRadius: "7.55px",
    background: "linear-gradient(144deg, #15D1D3 0%, #008BD9 100%)",
    maxWidth: "202px",
    height: "45px",
    color: "#fff",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "19px",
    lineHeight: "normal",
    textTransform: "capitalize",
    color: "#fff",
    boxShadow:
      "2.5167958736419678px 2.5167958736419678px 2.5167958736419678px 0px rgba(255, 255, 255, 0.10) inset, -2.5167958736419678px -2.5167958736419678px 2.5167958736419678px 0px rgba(0, 0, 0, 0.10) inset",
  },
}));
export const UserContext = createContext();

export default function AuthProvider(props) {
  const history = useHistory();
  const classes = useStyles();
  const [profile, setProfile] = useState({});
  const [banner, setBanner] = useState(null);
  const [exchangeBalance, setExchangeBalance] = useState({});
  const [coinList, setCointList] = useState([]);
  const [open, SetOpen] = useState(false);
  const [autoRenewOpen, setAutoRenewOpen] = useState(false);
  const [isTrue, setIsTrue] = useState(false);
  const [planExpiryDate, setPlanExpiryDate] = useState("");
  const [userPlan, setUserPlan] = useState({
    planExpireStatus: false,
    planSubscription: false,
  });
  const [loading, setLoading] = useState({
    userPlan: false,
  });
  const handleClose = () => {
    SetOpen(false);
    // setTimeout(() => {
    //   if (exchangeBalance.allExchangeTotal < 10) {
    //     setAutoRenewOpen(true);
    //   } else {
    //     getMyPlanDetails();
    //   }
    // }, 10000);
  };
  const handleCloseAutoRenewOpen = () => {
    setAutoRenewOpen(false);
    // getMyPlanDetails();
  };
  const handleClosePlan = () => {
    setUserPlan({
      planExpireStatus: false,
      planSubscription: false,
    });
  };
  async function getViewMyProfile(values) {
    const token = localStorage.getItem("token");
    setLoading({
      userPlan: true,
    });
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.viewMyProfile,
        headers: { token: token },
      });
      setLoading({
        userPlan: false,
      });
      if (res.data.statusCode === 200) {
        setProfile(res?.data?.result);
        setPlanExpiryDate(res?.data?.result?.planExpiryDate);
      } else {
        if (res.data.responseMessage !== "jwt malformed") {
          toast.error(res.data.responseMessage);
        }
      }
    } catch (error) {
      setLoading({
        userPlan: false,
      });
      if (
        error.response?.data?.responseMessage === "User not found" ||
        error.response?.data?.responseMessage === "jwt malformed"
      ) {
        window.localStorage.clear();
        history.push("/login");
      }
    }
  }
  const getMyPlanDetails = async (values) => {
    const token = localStorage.getItem("token");
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.viewMyProfile,
        headers: { token: token },
      });

      if (res.data.statusCode === 200) {
        setPlanExpiryDate(res.data.result.planExpiryDate);
        setUserPlan({
          planExpireStatus: res.data.result.planExpireStatus,
          planSubscription: res.data.result.planExpireStatus,
        });
      } else {
        if (res.data.responseMessage !== "jwt malformed") {
          toast.error(res.data.responseMessage);
        }
      }
    } catch (error) { }
  };

  const exchangeWallet = async (values) => {
    const token = localStorage.getItem("token");
    try {
      const res = await Axios({
        method: "POST",
        url: ApiConfig.exchangeWallet,
        headers: { token: token },
      });
      if (res.data.statusCode === 200) {
        setExchangeBalance(res?.data?.result);
      } else {
        // toast.error(res.data.responseMessage);
      }
    } catch (error) {
      if (error.response?.data.responseMessage !== "jwt malformed") {
        if (error.response?.data.responseMessage) {
          toast.error(error.response?.data.responseMessage);
        } else {
          toast.error("Something went wrong!");
        }
      }
    }
  };
  const advertismentBanner = async (values) => {
    const token = localStorage.getItem("token");
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.getAdvertismentForUser,
        headers: {
          token: token,
        },
      });

      if (res.data.statusCode === 200) {
        if (res?.data?.result[0] != undefined) {
          setTimeout(() => {
            setBanner(res?.data?.result[0]?.img);
            SetOpen(true);
          }, 5000);
        }
        // else if (
        //   res?.data?.result[0] == undefined &&
        //   exchangeBalance.allExchangeTotal < 10
        // ) {
        //   setTimeout(() => {
        //     setAutoRenewOpen(true);
        //   }, 5000);
        // } else {
        //   getMyPlanDetails();
        // }
      } else {
        if (res.data.responseMessage !== "jwt malformed") {
          toast.error(res.data.responseMessage);
        }
      }
    } catch (error) {
      if (
        error?.response?.data?.responseMessage == "User not found" ||
        error?.response?.data?.responseMessage == "jwt malformed"
      ) {
        window.localStorage.clear();
        history.push("/login");
      }
      if (error.response.data.statusCode == 404) {
      }
    }
  };
  const useSetStateAfterDelay = (delay) => {
    setIsTrue(true);

    setTimeout(() => {
      setIsTrue(false);
    }, 15000);
  };
  // coin list websocket
  const [search, setSearch] = useState("");
  const [type, setType] = useState("HOT");
  const [marketType, setMarketType] = useState("FUTURES");
  const [exchange, setExchange] = useState("Binance");
  const [socketLoading, setSocketLoading] = useState(false);
  const [availableExchanges, setAvailableExchanges] = useState([]);
  // const [webData, setwebData] = useState(true);
  const handleCoinFilter = (search, marketType, type) => {
    setSearch(search);
    setMarketType(marketType);
    setType(type);
  };

  // const ws = new WebSocket("ws://172.16.6.204/");

  useEffect(() => {
    setSocketLoading(true);
    const ws = new WebSocket(socketURL);
    const apiCall = {
      type: "getTickers",
      userId: profile._id,
      query: {
        exchange: exchange,
        market: "USDT",
        type: type,
        marketType: marketType,
        search: search?.toUpperCase(),
        limit: 150,
        page: 1,
      },
    };
    ws.onopen = (event) => {
      console.log("WebSocket connection established.");
      ws.send(JSON.stringify(apiCall));
    };
    ws.onmessage = function (event) {
      try {
        if (event.data !== "[object Promise]") {
          const json = JSON.parse(event.data);
          setCointList(json);
          setSocketLoading(false);
          // if( json?.marketType == marketType ||
          //   json?.type == type){
          //     setwebData(false);
          // }else{
          //   setwebData(true);
          // }
        }
      } catch (err) {
        console.log("Error parsing JSON:", err);
        setSocketLoading(false);
      }
    };
    ws.onclose = () => {
      console.log("WebSocket connection closed. Retrying...");
    };
    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
      setSocketLoading(false);
      // Optionally, you can add additional error handling logic here
    };
    // Clean up the WebSocket connection when the component unmounts
    return () => {
      ws.close();
    };
  }, [search, type, marketType, exchange]);

  const token = localStorage.getItem("token");

  const connectedExchangeList = async (value) => {
    try {
      const res = await Axios({
        method: "GET",
        url: ApiConfig.conectexchangeList,
        headers: { token: token },
      });
      const exchangeNames = res?.data?.result.map((item) => item.exchangeName);
      setAvailableExchanges(exchangeNames);
      if (res?.data?.result[0] != undefined) {
        setExchange(res?.data?.result[0].exchangeName);
      } else {
        // toast.error("res.data.responseMessage");
      }
    } catch (error) {
      // toast.error(error.response.data.responseMessage);
    }
  };

  useMemo(() => {
    if (token) {
      getViewMyProfile();
      exchangeWallet();
      connectedExchangeList();
    }
  }, [token]);
  let data = {
    profile,
    loading,
    exchangeBalance,
    coinList,
    exchange,
    setExchange,
    socketLoading,
    isTrue,
    availableExchanges,
    setAvailableExchanges,
    // webData,
    getViewMyProfile: () => getViewMyProfile(),
    exchangeWallet: () => exchangeWallet(),
    advertismentBanner: () => advertismentBanner(),
    useSetStateAfterDelay: () => useSetStateAfterDelay(),
    handleCoinFilter: (search, marketType, type) =>
      handleCoinFilter(search, marketType, type),
  };
  return (
    <UserContext.Provider value={data}>
      {props.children}
      {open && (
        <Dialog
          open={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
          PaperProps={{
            classes: {
              root: classes.root,
            },
            style: {
              maxWidth: 480,
              width: "100%",
            },
          }}
        >
          <Box
            style={{
              display: "grid",
              background: "#2A2A2A",
              justifyContent: "center",
              padding: "6px",
            }}
          >
            <div className={classes.dialogDiv} onClick={handleClose}>
              <img src="/images/tagcross.svg" alt="cross icon" />
            </div>
            <div className={classes.dialogBnnerDiv} style={{}}>
              <img
                src={banner}
                className={classes.dialogBnnerImg}
                alt="baner"
              />
            </div>
          </Box>
        </Dialog>
      )}
      {autoRenewOpen && exchangeBalance.allExchangeTotal < 10 && (
        <Dialog
          open={autoRenewOpen}
          onClose={handleCloseAutoRenewOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
          PaperProps={{
            classes: {
              root: classes.rootDialogBox,
            },
            style: {
              maxWidth: 315,
              width: "100%",
            },
          }}
        >
          <Box
            style={{
              display: "grid",
              gap: "50px",
              textAlign: "center",
              margin: "30px 31px",
            }}
          >
            <Box style={{ display: "grid", gap: "30px", textAlign: "center" }}>
              <Typography className={classes.subMainHead}>
                Insufficient Fuel Fee!
              </Typography>
              <Typography
                style={{ fontSize: "18px" }}
                className={classes.subMain}
              >
                Your fuel fee is below $10.
              </Typography>
              <Typography
                style={{ fontSize: "18px" }}
                className={classes.subMain}
              >
                To ensure uninterrupted <br />
                access to Olympus Services, <br />
                please deposit <br />
                the required fuel fee.
              </Typography>
            </Box>

            <Box style={{ display: "flex", justifyContent: "center" }}>
              <Button
                className={classes.DisableButton}
                onClick={() => {
                  history.push({
                    pathname: "/asset",
                    state: { tabName: "deposit" },
                  });
                  setAutoRenewOpen(false);
                }}
                fullWidth
              >
                Deposit Now
              </Button>
            </Box>
          </Box>
        </Dialog>
      )}
      {userPlan.planExpireStatus && userPlan.planSubscription && (
        <Dialog
          open={userPlan.planExpireStatus && userPlan.planSubscription}
          onClose={handleClosePlan}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
          PaperProps={{
            classes: {
              root: classes.rootDialogBox,
            },
            style: {
              maxWidth: 315,
              width: "100%",
            },
          }}
        >
          <Box
            style={{
              display: "grid",
              gap: "50px",
              textAlign: "center",
              margin: "30px 31px",
            }}
          >
            <Box style={{ display: "grid", gap: "30px", textAlign: "center" }}>
              <Typography className={classes.subMainHead}>
                Plan Expired: Renew Now!
              </Typography>
              <Typography
                style={{ fontSize: "18px" }}
                className={classes.subMainSub}
              >
                Your plan has been expired!
              </Typography>
              <Typography
                style={{ fontSize: "18px" }}
                className={classes.subMainSub}
              >
                To continue using Olympus Services without any disruption,
                please renew your plan immediately.
              </Typography>
            </Box>

            <Box style={{ display: "flex", justifyContent: "center" }}>
              <Button
                className={classes.DisableButton}
                onClick={() => {
                  handleClosePlan();
                  history.push({
                    pathname: "/pricing",
                  });
                }}
                fullWidth
              >
                Renew Now
              </Button>
            </Box>
          </Box>
        </Dialog>
      )}
    </UserContext.Provider>
  );
}
