import React from "react";
import { Button, makeStyles, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "fit-content",
    padding: "10px 10px 20px 10px",
    background:"#161616",
    borderRadius: "24px",
    "& .MuiDialogContent-root": {
      flex: "none !important",
    },
    "& .MuiDialogActions-root": {
      marginRight: "0px !important",
    },
  },
  Titlemain: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "23.989px",
    lineHeight: "36px",
    color: "#fff",
  },
  subMain: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "24px",
    color: "#fff",
  },
  yesNoButton: {
    padding:'10px 50px',
    background: "linear-gradient(180.99deg, #2FF3FF -25%, #1E92AA 141.48%)",
    borderRadius: "10px",
    color: "#fff",
  },
  NoButton:{
    padding:'10px 50px',
    background: "#161616",
    borderRadius: "10px",
    color: "#fff",
    border:"1px solid #2FF3FF"
  }
}));
export default function AlertDialog({
  open,
  handleClose,
  title,
  desc,
  confirmationHandler,
}) {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xs"
        PaperProps={{
          classes: {
            root: classes.root,
          },
        }}
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
          <Typography className={classes.Titlemain}> {title}</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{
              textAlign: "center",
              fontSize: "17px",
            }}
          >
            <Typography
              style={{ fontSize: "18px" }}
              className={classes.subMain}
            >
              {" "}
              {desc}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{ display: "flex", justifyContent: "space-around" }}
        >
         <Button
            className={classes.yesNoButton}
            onClick={() => {
              handleClose();
              confirmationHandler();
            }}
          >
            Yes
          </Button>
          <Button
            className={classes.NoButton}
            onClick={handleClose}
            autoFocus
          >
            No
          </Button>
         
        </DialogActions>
      </Dialog>
    </div>
  );
}
