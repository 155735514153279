import React from "react";

import { makeStyles } from "@material-ui/core";
import { imageConditions } from "../utils/rankBadges";
export default function ImageBadge({
  subscription,
  activatedDirect,
  activatedPackageMonth,
  totalTeam,
  getImageLevelProps,
  teamData
}) {
  const useStyles = makeStyles((theme) => ({
    image: {
      width: "68px",
      height: "68px",
      borderRadius: "100%",
      objectFit: "contain",
    },
  }));

  const classes = useStyles();
  if (subscription && activatedDirect && activatedPackageMonth && totalTeam && teamData) {
    function getImageLevel(conditions) {
      for (let i = imageConditions.length - 1; i >= 0; i--) {
        const level = imageConditions[i];
        const conditionKeys = Object.keys(level.condition);
        if (
          conditionKeys.every((key) => conditions[key] >= level.condition[key])
        ) {
          return level.level;
        }
      }

      return 0; // If no condition is met, return 0 or any default level you prefer.
    }
    const conditions = {
      subscription: subscription,
      activatedDirect: activatedDirect,
      activatedPackageMonth: activatedPackageMonth,
      totalTeam: totalTeam,
      team60: teamData.percentsixty,
      team40: teamData.percentforty
    };
    const imageLevel = getImageLevel(conditions);
    if (getImageLevelProps) {
      if (imageLevel > 9) {
        getImageLevelProps(9,imageLevel);
      } else {
        getImageLevelProps(imageLevel,imageLevel);
      }
    }
    if (imageLevel > 0) {
      return (
        <img
          src={`https://res.cloudinary.com/dovl2qxdj/image/upload/v1697811571/level${imageLevel}.png`}
          alt={`badge-level-${imageLevel}`}
          className={classes.image}
        />
      );
    } else {
      return (
        <img
          src={`https://res.cloudinary.com/dovl2qxdj/image/upload/v1697811571/level0.png`}
          alt={`badge-level-${imageLevel}`}
          className={classes.image}
        />
      );
    }
  } else {
    return "";
  }
}
