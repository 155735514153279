import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { AuthContext } from "src/context/Auth";
// import { useHistory } from "react-router-dom";

export default function AuthGuard(props) {
  const { children } = props;
  const auth = useContext(AuthContext);
  let {userLoggedIn} = auth
  
  if (!userLoggedIn) {
    return <Redirect to="/login" />;
  }

  return <>{children}</>;
}
